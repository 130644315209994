<template>
    <div>
        <div class="flex items-center md:items-end mb-10">
            <slot name="title">
                <UIHeading >
                    <slot name="title-text"/>
                </UIHeading>
            </slot>

            <ULink class="hidden md:block ml-auto font-bold text-blue-400 underline hover:no-underline hover:text-blue-500" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
        </div>

        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div v-for="service of services" class="grid gap-3">
                <h2 class="text-xl font-bold flex items-center gap-3">
                    <UIIcon v-if="service.icon" :name="service.icon" class="text-blue-400" />
                    {{service.title}}
                </h2>
                <div>{{service.shortDescription}}</div>
            </div>

            <div class="flex justify-center">
                <ULink class="md:hidden font-bold text-blue-400 underline hover:no-underline hover:text-blue-500" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
            </div>
        </div>

        <div class="flex flex-col gap-6 justify-center mt-10 md:mt-12">
            <div class="font-xl text-center font-semibold">Contact us, and we’ll help you pick the best time for your service!</div>

            <div class="text-center">
                <UButton size="xl" variant="solid">Get Assistance Now</UButton>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps(['readMoreLink']);

const { data: services } = await useAsyncData(() => queryContent('services').find(), {
    transform: (data) => data.sort(
        (a = 0, b = 0) => a.order - b.order
    ).filter(
        ({_dir}) => _dir === 'services'
    ).map(({title, shortDescription, _path, icon}) => ({
        title, shortDescription, _path, icon
    }))
});
</script>
